<template>
  <el-dialog
    v-model="dialogVisible"
    title="审批设置"
    width="70%"
    :before-close="handleClose"
    class="config-manager-dialog"
  >
    <el-input
      v-model="searchQuery"
      placeholder="搜索设置项"
      prefix-icon="el-icon-search"
      clearable
      class="mb-4"
    />
    <el-table
      v-loading="loading"
      element-loading-text="加载中..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      :data="filteredConfigs"
      :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
    >
      <el-table-column prop="friendlyName" label="设置项" width="200" show-overflow-tooltip>
        <template #default="{ row }">
          <el-tooltip :content="row.friendlyName" placement="top" effect="light">
            <span>{{ row.friendlyName }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="value" label="设置值" min-width="300">
        <template #default="{ row }">
          <div class="value-container">
            <el-select
              v-model="row.value"
              multiple
              filterable
              remote
              reserve-keyword
              placeholder="请选择"
              :remote-method="(query) => remoteSearch(query, row)"
              :loading="loading"
              @change="updateConfig(row)"
              class="custom-select"
              :disabled="!canEdit"
            >
              <el-option
                v-for="item in row.options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
                <el-avatar :size="24" :src="item.avatar" class="mr-2"></el-avatar>
                {{ item.name }}
              </el-option>
            </el-select>
            <el-icon v-if="canEdit" class="add-icon" @click.stop="openContactSelector(row)">
              <plus />
            </el-icon>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="description" label="说明" min-width="200" show-overflow-tooltip>
        <template #default="{ row }">
          <el-tooltip :content="row.description" placement="top" effect="light">
            <span>{{ row.description }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <!-- 显示当前用户的编辑权限状态 -->
    <div class="mt-4">
      <el-alert
        :title="canEdit ? '您有权编辑这些设置' : '您只能查看这些设置'"
        :type="canEdit ? 'success' : 'warning'"
        :closable="false"
      >
      </el-alert>
    </div>

    <!-- 显示配置管理员 -->
    <!-- <div v-if="configManagers.length > 0" class="config-managers">
      <h3>配置管理员:</h3>
      <el-avatar v-for="manager in configManagers" :key="manager.id" :size="32" :src="manager.avatar">
        {{ manager.name }}
      </el-avatar>
    </div> -->
  </el-dialog>
</template>

<script>
import { ref, computed, watch, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import { Plus } from '@element-plus/icons-vue'
import axios from 'axios'
import { useStore } from 'vuex'
import * as ww from '@wecom/jssdk'

const configKeyToFriendlyName = {
  'WECOM_FINANCE_APPROVER_ID': '报销审批人',
  'WECOM_CC_APPROVER_ID': '报销抄送人',
  'PAYMENT_WECOM_FINANCE_APPROVER_ID': '付款审批人',
  'PAYMENT_WECOM_CC_APPROVER_ID': '付款抄送人',
  'PAYMENT_WECOM_THIRD_PARTY_APPROVER_ID': '三方付/退/返款审批人',
  'PAYMENT_WECOM_PROJECT_APPROVER_ID': '项目付款审批人',
  'PAYMENT_WECOM_OTHER_APPROVER_ID': '其它付款审批人',
  'CONFIG_MANAGER_USERS': '配置管理员',
}

export default {
  name: 'ConfigManagerDialog',
  props: {
    visible: Boolean,
  },
  emits: ['update:visible'],
  components: {
    Plus,
  },
  setup(props, { emit }) {
    const store = useStore()
    const configs = ref([])
    const dialogVisible = ref(props.visible)
    const searchQuery = ref('')
    const loading = ref(false)
    const wwInitialized = ref(false)
    const canEdit = ref(false)
    const configManagers = ref([])

    const filteredConfigs = computed(() => {
      if (!searchQuery.value) return configs.value
      const lowercaseQuery = searchQuery.value.toLowerCase()
      return configs.value.filter(config => 
        (config.friendlyName && config.friendlyName.toLowerCase().includes(lowercaseQuery)) ||
        (config.description && config.description.toLowerCase().includes(lowercaseQuery)) ||
        (Array.isArray(config.value) && config.value.some(v => 
          v && v.name && v.name.toLowerCase().includes(lowercaseQuery)
        ))
      )
    })

    watch(() => props.visible, (newValue) => {
      dialogVisible.value = newValue
      if (newValue) {
        resetData()
        fetchConfigs()
      }
    })

    watch(dialogVisible, (newValue) => {
      emit('update:visible', newValue)
    })

    const resetData = () => {
      configs.value = []
      searchQuery.value = ''
      canEdit.value = false
      configManagers.value = []
    }

    const fetchConfigs = async () => {
      loading.value = true
      try {
        const response = await axios.get('/api/get_all_configs')
        if (response.data.code === 200) {
          const data = response.data.data
          configs.value = data.configs.map(config => {
            let value, options;
            if (Array.isArray(config.value)) {
              value = config.value.map(user => user.id);
              options = config.value;
            } else if (config.key === 'CONFIG_MANAGER_USERS') {
              value = config.value.split(',');
              options = data.config_managers;
            } else {
              value = config.value;
              options = [];
            }
            return {
              key: config.key,
              friendlyName: configKeyToFriendlyName[config.key] || config.key,
              value: value,
              options: options,
              description: config.description
            };
          });
          canEdit.value = data.canEdit
          configManagers.value = data.config_managers
        } else {
          ElMessage.error('获取配置信息失败')
        }
      } catch (error) {
        ElMessage.error('获取配置信息失败')
        console.error(error)
      } finally {
        loading.value = false
      }
    }

    const remoteSearch = async (query, row) => {
      if (query) {
        loading.value = true
        try {
          const response = await axios.post('/api/search_users', { query })
          row.options = response.data.users.map(user => ({
            id: user.userid,
            name: user.name,
            avatar: user.avatar
          }))
        } catch (error) {
          console.error('搜索用户失败:', error)
          ElMessage.error('搜索用户失败')
        } finally {
          loading.value = false
        }
      } else {
        row.options = []
      }
    }

    const initWechatSDK = async () => {
      try {
        // 注册 @wecom/jssdk，提供额外的第三方应用身份信息
        await ww.register({
          beta: true,
          debug: true,
          corpId: store.state.corpId,
          agentId: store.state.agentId,
          jsApiList: [
            'selectEnterpriseContact',
            'openUserProfile',
            'checkJsApi',
          ],
          getConfigSignature: getConfigSignature,
          getAgentConfigSignature: getAgentConfigSignature,
        })
        wwInitialized.value = true
      } catch (error) {
        console.error('初始化微信 JS-SDK 失败：', error)
      }
    }

    const getConfigSignature = async (url) => {
      try {
        const response = await axios.get(store.state.baseUrl + '/wecom_signature', { params: { url } })
        const configSignature = response.data.data
        return {
          timestamp: configSignature.timestamp,
          nonceStr: configSignature.noncestr, // 使用 noncestr 字段
          signature: configSignature.signature,
        }
      } catch (error) {
        console.error('获取微信 JS-SDK 配置信息失败：', error)
        throw error
      }
    }

    const getAgentConfigSignature = async (url) => {
      try {
        const response = await axios.get(store.state.baseUrl + '/agent_signature', { params: { url } })
        const agentConfigSignature = response.data.data
        return {
          timestamp: agentConfigSignature.timestamp,
          nonceStr: agentConfigSignature.noncestr,
          signature: agentConfigSignature.signature,
        }
      } catch (error) {
        console.error('获取第三方应用签名失败：', error)
        throw error
      }
    }

    const openContactSelector = async (row) => {
      if (!wwInitialized.value) {
        ElMessage.error('企业微信 SDK 未初始化')
        return
      }

      try {
        const res = await ww.selectEnterpriseContact({
          fromDepartmentId: -1,
          mode: 'single',
          type: ['user'],
        })

        const selectedUser = res.result.userList && res.result.userList[0]
        if (selectedUser) {
          const existingUser = row.options.find(user => user.id === selectedUser.id)
          if (!existingUser) {
            const newUser = {
              id: selectedUser.id || '',
              name: selectedUser.name || '',
              avatar: selectedUser.avatar || ''
            }
            row.options.push(newUser)
            if (!row.value) row.value = []
            row.value.push(newUser.id)
            updateConfig(row)
          } else {
            ElMessage.warning('该用户已存在')
          }
        }
      } catch (error) {
        if (error.errMsg && error.errMsg.indexOf('selectEnterpriseContact:cancel') === -1) {
          console.error('选择用户失败:', error)
          ElMessage.error('选择用户失败')
        }
      }
    }

    const updateConfig = async (config) => {
      if (!canEdit.value) {
        ElMessage.warning('您没有修改权限')
        return
      }
      try {
        await axios.post('/api/update_config', {
          key: config.key,
          value: config.value.join(',')
        })
        ElMessage.success('设置更新成功')
      } catch (error) {
        ElMessage.error('设置更新失败')
        console.error(error)
      }
    }

    const handleClose = () => {
      emit('update:visible', false)
    }

    onMounted(() => {
      if (props.visible) {
        fetchConfigs()
      }
      initWechatSDK()
    })

    return {
      configs,
      filteredConfigs,
      updateConfig,
      dialogVisible,
      handleClose,
      searchQuery,
      remoteSearch,
      loading,
      openContactSelector,
      Plus,
      canEdit,
      configManagers,
    }
  }
}
</script>

<style scoped>
.config-manager-dialog {
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
}

.config-manager-dialog :deep(.el-dialog__body) {
  padding: 20px 30px;
}

.mb-4 {
  margin-bottom: 16px;
}

.el-table {
  margin-bottom: 20px;
}

.value-container {
  display: flex;
  align-items: center;
  position: relative;
}

.custom-select {
  width: 100%;
}

.custom-select :deep(.el-select__tags) {
  flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.custom-select :deep(.el-select__tags)::-webkit-scrollbar {
  display: none;
}

.add-icon {
  cursor: pointer;
  font-size: 16px;
  color: #409EFF;
  transition: all 0.3s;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.add-icon:hover {
  color: #66b1ff;
  transform: translateY(-50%) scale(1.1);
}

/* 为了给加号图标留出空间 */
.custom-select :deep(.el-input__inner) {
  padding-right: 40px;
}

.custom-select :deep(.el-input.is-disabled .el-input__inner) {
  background-color: #f5f7fa;
  border-color: #e4e7ed;
  color: #c0c4cc;
  cursor: not-allowed;
}

.config-managers {
  margin-top: 20px;
}

.config-managers h3 {
  margin-bottom: 10px;
}

.config-managers .el-avatar {
  margin-right: 10px;
}

.mt-4 {
  margin-top: 20px;
}
</style>
